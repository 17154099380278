/**
 * Use this function to fetch a specific item saved in local-storage.
 *
 * @param {string} key - The key of the local-storage item you are trying to fetch.
 * @param {number} user_id - The ID of the user the local storage was saved under.
 * @returns {any}
 */
export function getLocalStorageItem(key: string, user_id: number) {
  const item = localStorage.getItem(`${key}-${user_id}`);

  return item ? JSON.parse(item) : item;
}

/**
 * Use this function to save a specific item to local-storage.
 *
 * @param {string} key - The key you would like to save your item to local-storage as.
 * @param {number} user_id - The ID of the user you would like to set the local storage for.
 * @returns {void}
 */
export function setLocalStorageItem(key: string, user_id: number, body: any) {
  return localStorage.setItem(`${key}-${user_id}`, JSON.stringify(body));
}

/**
 * Use this function to remove a specific item from local-storage.
 *
 * @param {string} key - The key for the item you would like to remove from local-storage.
 * @param {number} user_id - The ID of the user who initially set the local-storage item you are trying to remove.
 * @returns {void}
 */
export function removeLocalStorageItem(key: string, user_id: number) {
  return localStorage.removeItem(`${key}-${user_id}`);
}
