import { QueryClient, useQuery } from "@tanstack/react-query";

import { instance } from "@/react/hooks/api";
import { createAxiosMutation } from "@/react/hooks/react-query";
import { User } from "@/react/types/users/user";
import { useIsAuthenticated } from "@/react/hooks/useIsAuthenticated";

interface UpdateUserParams {
  user: User;
  body: {
    email?: string;
    first_name?: string;
    last_name?: string;
    mobile_phone?: string;
    employee_role_id: number | undefined | null;
  };
}

export const useCurrentUserQuery = () => {
  const { isAuthenticated } = useIsAuthenticated();
  return useQuery(
    ["current-user"],
    async () => {
      const { data } = await instance().get<User>("/users/me");
      return data;
    },
    {
      enabled: isAuthenticated,
      // We can safely cache this data forever and manually invalidate the cache when the user edits their profile.
      staleTime: Infinity,
    }
  );
};

const invalidateCurrentUserQuery = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(["current-user"]);
};

export const useEditUserMutation = createAxiosMutation(
  async ({ user, body }: UpdateUserParams) => {
    const { data } = await instance().patch(`/users/${user.id}`, body);
    return data;
  },
  {
    errorToast: (error) => ({
      message:
        error.response?.data?.reason ??
        "There was an error updating your profile.",
    }),
    successToast: (_data, variables, _context) => ({
      message:
        variables.body.email !== variables.user.email
          ? "Your profile has been updated. An email has been sent to you to confirm the changes to your email address."
          : "Your profile has been updated successfully.",
    }),
    onSettled: (queryClient) => {
      invalidateCurrentUserQuery(queryClient);
    },
  }
);
